@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TabletGothicCompressed";
  src: url("/public/TabletGothic_Complete/Tablet\ Gothic\ Compressed-SemiBold.otf");
}

@font-face {
  font-family: "TabletGothicNarrow";
  src: url("/public/TabletGothic_Complete/Tablet\ Gothic\ Narrow-Semibold.otf");

}

@font-face {
  font-family: "TabletGothic";
  src: url("/public/TabletGothic_Complete/Tablet\ Gothic-SemiBold.otf");

}

.narrowfont {
  font-family: "TabletGothicNarrow", sans-serif;
  text-transform: uppercase;
}

.compressedfont {
  font-family: "TabletGothicCompressed", sans-serif;
}

.defaultfont {
  font-family: "TabletGothic", sans-serif;
}


body {
  margin: 0;
  font-family: "TabletGothic", "Avenir", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}